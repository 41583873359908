import { JobStatusNo } from 'Enum/Enum/JobStatusNo';
import { titleCase } from 'title-case';
import {
  isActiveMonitoringProfile,
  isDeletedMonitoringProfile,
  isFailedMonitoringProfileStatus,
  isPausedMonitoringProfileByUser,
  isPausedMonitoringProfileNoQuota,
} from './monitoring_profile';
import { isAdmin } from './utils';

const domainPropertyPrefix = 'sc-domain:';

// replace Search Console domain property prefix in siteURL
const replaceDomainPropertyPrefix = (siteURL, replaceWith = 'Domain Property: ') =>
  siteURL.replace(domainPropertyPrefix, replaceWith);

// format URL as HTML link including normalization of Search Console domain property prefix
const formatLink = (URL) => {
  if (!URL) {
    return URL;
  }

  return `<a class="table-link" rel="noreferrer noopener" target="_blank"
    href="${replaceDomainPropertyPrefix(URL, '//')}">${replaceDomainPropertyPrefix(URL)}</a>`;
};

// format cell with multiple URLs as HTML link and popover with list of all URLs formatted as HTML links
const formatCellWithURLs = (params) => {
  if (!params.value) {
    return params.value;
  }

  if (!params.value.length) {
    return '';
  }

  const links = params.value.map((sitemap) => formatLink(sitemap));

  if (links.length === 1) {
    return links.shift();
  }

  const firstLink = links[0];

  return `${firstLink} and <a href="javascript:void(0)" title="All URLs"
        x-data='{popover: null, links: ${JSON.stringify(links)}}'
        x-init="popover = URLinspector.initializePopoverLinksList($el, links)"
        @click.outside="URLinspector.hidePopoverInstanceOutsideClick(event, popover)"
        @keyup.esc.window="popover.hide()">${links.length - 1} more URL(s)</a>`;
};

// format list of HTML links for popover
const formatPopoverLinksList = (links) => {
  links = links.map((link) => `<li class="list-group-item">${link}</li>`);

  return `<ul class="list-group list-group-flush">${links.join('')}</ul>`;
};

// format monitored property grid actions cell with "modify" and "view" actions for each monitored property
const formatMonitoringProfileActionsCell = (params) => {
  return `<div class="d-flex">
            <a title="View Inspection Results for Monitored Property" class="btn btn-sm btn-outline-primary ms-3" 
                href="/monitored/${params.value}/results">
                <i class="fas fa-lg fa-search me-2"></i> View
            </a>
            <a title="Modify Monitored Property" href="/monitored/${params.value}" class="btn btn-sm btn-outline-secondary ms-3">
                <i class="fas fa-lg fa-edit me-2"></i> Modify
            </a>
        </div>`;
};

// format JobStatusNo value as display title separating QUOTAINTERNAL/EXTERNAL and NOACCESS to two words, and converting to title case
const formatMonitoringProfileStatusTitle = (value) => {
  value = value || JobStatusNo.UNKNOWN;

  return titleCase(
    JobStatusNo[value]
      .replace('_', ' ')
      .replace(/QUOTA\s{0}/, 'QUOTA ')
      .replace(/NOACCESS\s{0}/, 'NO ACCESS')
      .toLowerCase()
  );
};

// format monitoring profile status cell with status icon based on JobStatusNo value
const formatMonitoringProfileStatusCell = (params) => {
  // default = unknown status
  const value = params.value || JobStatusNo.UNKNOWN;

  let title = formatMonitoringProfileStatusTitle(value);
  let link = '';

  let badgeClass;
  let iconClass;

  // convert JobStatusNo to icon based on JobStatusNo value
  switch (true) {
    case isActiveMonitoringProfile(value):
      badgeClass = 'bg-success';
      iconClass = 'fa-play';
      break;

    case isPausedMonitoringProfileNoQuota(value):
      badgeClass = 'bg-warning';
      iconClass = 'fa-exclamation-triangle';
      title = 'Paused because you have no quota left. Please upgrade your account.';
      link = '/pricing';
      break;

    case isPausedMonitoringProfileByUser(value):
      badgeClass = 'bg-warning';
      iconClass = 'fa-pause';
      break;

    case isFailedMonitoringProfileStatus(value):
      badgeClass = 'bg-danger';
      iconClass = 'fa-stop';
      link = '/logout?reauthorize=true';
      title =
        'Monitoring Profile was paused, because access to your Google Search Console was revoked. Please re-authorize the access here.';
      break;

    case isDeletedMonitoringProfile(value):
      badgeClass = 'bg-danger';
      iconClass = 'fa-trash';
      break;

    default:
      badgeClass = 'bg-secondary';
      iconClass = 'fa-question';
  }

  if (link !== '') {
    return `<a href="${link}"><span class="badge ${badgeClass}" title="Status: ${title}"><i class="fa fa-lg fa-fw ${iconClass}"></i></span></a>`;
  }

  return `<span class="badge ${badgeClass}" title="Status: ${title}"><i class="fa fa-lg fa-fw ${iconClass}"></i></span>`;
};

// mapping of priority values - TODO: move to enum?
const QuotaPriority = {
  STOP: 9999,
  SLOW: 1000,
  LOW: 300,
  MEDIUM: 200,
  HIGH: 100,
};

// mapping of priority values to titles
const QuotaPriorityTitle = {
  [QuotaPriority.STOP]: 'Stopped',
  [QuotaPriority.SLOW]: 'Slow Speed',
  [QuotaPriority.LOW]: 'Low Speed',
  [QuotaPriority.MEDIUM]: 'Medium Speed',
  [QuotaPriority.HIGH]: 'High Speed',
};

// format QuotaPriority value as display title
const formatMonitoringProfilePriorityTitle = (value) => {
  return QuotaPriorityTitle[value] || 'Unknown';
};

// format monitoring profile priority cell with priority icon based on priority value
const formatMonitoringProfilePriorityCell = (params) => {
  let dotClass;
  const title = formatMonitoringProfilePriorityTitle(params.value);

  switch (params.value) {
    // QuotaPriority = 100 = HIGH priority (Gold)
    case QuotaPriority.HIGH:
      dotClass = 'speed-high';
      break;

    // QuotaPriority = 200 = MEDIUM priority (Silver)
    case QuotaPriority.MEDIUM:
      dotClass = 'speed-medium';
      break;

    // QuotaPriority = 300 = LOW priority (Bronze)
    case QuotaPriority.LOW:
      dotClass = 'speed-low';
      break;

    // QuotaPriority = 1000 = SLOW priority (Basic)
    case QuotaPriority.SLOW:
      dotClass = 'speed-slow';
      break;

    // QuotaPriority = 9999 = STOP
    case QuotaPriority.STOP:
      dotClass = 'bg-danger';
      break;

    // unknown priority
    default:
      dotClass = 'bg-secondary';
  }

  return `<span class="p-2 rounded-circle translate-middle ${dotClass} position-absolute" title="Priority: ${title}"> </span>`;
};

// format monitored property grid actions cell with "modify" and "view" actions for each monitored property
const formatMonitoringScope = (scope, id) => {
  return `<a title="View Inspection Results for Monitored Property" href="/monitored/${id}/results">${scope}</a>`;
};

// format date cell to locale date time string
const formatDateCell = (value) => (value ? value.toLocaleString() : value);

// format Account ID with link to start impersonation for admins
const formatAccountID = (value) => {
  if (!value || !isAdmin()) {
    return value;
  }

  return `<a href="/admin/impersonation/${value}" title="Impersonate Account ID: ${value}">
           <i class="fa fa-eye fa-fw"></i>
          </a> ${value}`;
};

export {
  replaceDomainPropertyPrefix,
  formatLink,
  formatCellWithURLs,
  formatMonitoringProfileActionsCell,
  formatMonitoringProfileStatusCell,
  formatMonitoringProfileStatusTitle,
  formatMonitoringProfilePriorityCell,
  formatMonitoringProfilePriorityTitle,
  formatMonitoringScope,
  formatDateCell,
  formatPopoverLinksList,
  formatAccountID,
};
