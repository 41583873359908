// <editor-fold desc="JobStatusNo helpers">
import { JobStatusNo } from 'Enum/Enum/JobStatusNo';

function isActiveMonitoringProfile(JobStatusNoValue) {
  return [JobStatusNo.ACTIVE, JobStatusNo.ACTIVE_BOOST, JobStatusNo.RESUMED].includes(
    JobStatusNoValue
  );
}

function isPausedMonitoringProfileNoQuota(JobStatusNoValue) {
  return [JobStatusNo.PAUSED_QUOTAEXTERNAL, JobStatusNo.PAUSED_QUOTAINTERNAL].includes(
    JobStatusNoValue
  );
}

function isPausedMonitoringProfileByUser(JobStatusNoValue) {
  return [
    JobStatusNo.PAUSED_USER,
    JobStatusNo.PAUSED_ADMIN,
    JobStatusNo.PAUSED_RESCHEDULE,
  ].includes(JobStatusNoValue);
}

function isFailedMonitoringProfileStatus(JobStatusNoValue) {
  return [JobStatusNo.FAILED_RETRY, JobStatusNo.FAILED_STOP, JobStatusNo.PAUSED_NOACCESS].includes(
    JobStatusNoValue
  );
}

function isDeletedMonitoringProfile(JobStatusNoValue) {
  return [JobStatusNo.HIDDEN, JobStatusNo.DELETE_MARK, JobStatusNo.DELETE_DONE].includes(
    JobStatusNoValue
  );
}
// </editor-fold>

export {
  isActiveMonitoringProfile,
  isPausedMonitoringProfileNoQuota,
  isPausedMonitoringProfileByUser,
  isFailedMonitoringProfileStatus,
  isDeletedMonitoringProfile,
};
