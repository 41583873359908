// DO NOT EDIT. Code generated by GenEnums from Branch main Commit 83d6ab8aad29837a6cf360d8d5a58c35c2ffb408
//
// WHAT: Generated Enum Constants for JobStatusNo
// WHY: Because writing DB APIs should focus on good access and optimization, not boilerplate
// Owner: GenEnums
//
// Copyright (C) 2022 LinkResearchTools GmbH
//

//JobStatusNo  No: 109

enum JobStatusNo {
	UNKNOWN = 0,  // Unknown job status 
	FAILED_RETRY = 10,  // Failed job, retries left 
	FAILED_STOP = 19,  // Failed job that stopped after retries 
	PAUSED_ADMIN = 20,  // PAUSED by admin 
	PAUSED_USER = 21,  // PAUSED by user 
	PAUSED_QUOTAINTERNAL = 22,  // PAUSED by lack of internal quota 
	PAUSED_QUOTAEXTERNAL = 23,  // PAUSED by lack of external quota (i.e. GSC 429 errors) 
	PAUSED_NOACCESS = 24,  // PAUSED by lack of access privileges (e.g. GSC Account Deleted inside GSC 400 errors) 
	PAUSED_RESCHEDULE = 28,  // PAUSED for rescheduling (i.e. new priorities, new plan purchased, etc) 
	RESUMED = 29,  // RESUMED, needs to be continued 
	HIDDEN = 97,  // Hidden, but kept 
	DELETE_MARK = 98,  // Mark for delete 
	DELETE_DONE = 99,  // Delete done 
	ACTIVE = 100,  // Active 
	ACTIVE_BOOST = 101,  // Active Boosted 
}

export { JobStatusNo };