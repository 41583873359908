// format cell date value to midnight for comparison with built-in date filter
const dateFilterComparator = (filterLocalDateAtMidnight, cellValue) => {
  if (!cellValue) {
    return 0;
  }

  const cellDate = cellValue.setHours(0, 0, 0, 0);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export { dateFilterComparator };
