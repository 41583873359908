import * as ag from 'ag-grid-enterprise';
import {
  formatDateCell,
  formatMonitoringScope,
  formatMonitoringProfileActionsCell,
  replaceDomainPropertyPrefix,
  formatMonitoringProfileStatusCell,
  formatMonitoringProfilePriorityCell,
  formatAccountID,
  formatMonitoringProfileStatusTitle,
  formatMonitoringProfilePriorityTitle,
} from './formatters';
import { dateFilterComparator } from './filters';
import { isAdmin } from './utils';
import { isDeletedMonitoringProfile } from './monitoring_profile';

const coveragePercentageMultiplier = 100;

// configure columns, formatters, filters and grid options for monitored properties grid
const monitoredPropertiesGridOptions = {
  rowStyle: { 'padding-top': '4px' },
  columnDefs: [
    {
      headerName: 'Actions',
      field: 'id',
      filter: false,
      sortable: false,
      floatingFilter: false,
      cellClass: 'monitoring-profile-actions-cell',
      cellRenderer: formatMonitoringProfileActionsCell,
      pinned: 'left',
      cellStyle: { 'padding-bottom': '5px' },
      minWidth: 250,
    },
    {
      headerName: 'Status',
      headerTooltip: 'Monitoring Profile Status',
      field: 'JobStatusNo',
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: (params) => formatMonitoringProfileStatusTitle(params.value),
        comparator: (a, b) => {
          if (a === b) {
            return 0;
          }

          const valA = formatMonitoringProfileStatusTitle(a);
          const valB = formatMonitoringProfileStatusTitle(b);

          return valA > valB ? 1 : -1;
        },
      },
      sortable: false,
      floatingFilter: true,
      cellClass: 'monitoring-profile-actions-cell',
      cellRenderer: formatMonitoringProfileStatusCell,
      pinned: 'left',
      cellStyle: { 'padding-bottom': '5px' },
      maxWidth: 50,
      minWidth: 50,
    },
    {
      headerName: 'Prio',
      headerTooltip: 'Monitoring Profile Priority',
      field: 'QuotaPriority',
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: (params) => formatMonitoringProfilePriorityTitle(params.value),
        comparator: (a, b) => {
          if (a === b) {
            return 0;
          }

          const valA = formatMonitoringProfilePriorityTitle(a);
          const valB = formatMonitoringProfilePriorityTitle(b);

          return valA > valB ? 1 : -1;
        },
      },
      sortable: false,
      floatingFilter: true,
      cellClass: 'monitoring-profile-actions-cell',
      cellRenderer: formatMonitoringProfilePriorityCell,
      pinned: 'left',
      cellStyle: { 'padding-bottom': '3px' },
      maxWidth: 50,
      minWidth: 50,
    },
    {
      headerName: 'Monitoring Scope',
      field: 'PropertyFilter',
      filter: 'agTextColumnFilter',
      valueFormatter: (params) => replaceDomainPropertyPrefix(params.value),
      cellRenderer: (params) => formatMonitoringScope(params.value, params.data.id),
      pinned: 'left',
      cellStyle: { 'font-size': '150%' },
    },
    {
      headerName: 'GSC E-Mail',
      field: 'Email',
      filter: 'agSetColumnFilter',
      pinned: 'left',
      hide: !isAdmin(),
    },
    {
      headerName: 'Account ID',
      field: 'AccountID',
      filter: 'agSetColumnFilter',
      cellRenderer: (params) => formatAccountID(params.value),
      pinned: 'left',
      type: 'rightAligned',
      hide: !isAdmin(),
    },
    {
      headerName: 'Coverage %',
      field: 'InspectionCoveragePCT',
      filter: 'agNumberColumnFilter',
      filterParams: {
        numberParser: (input) => (input ? input * coveragePercentageMultiplier : input),
      },
      type: 'rightAligned',
      cellStyle: { 'font-size': '150%' },
      valueFormatter: ({ value }) => (value ? `${value / coveragePercentageMultiplier} %` : value),
      pinned: 'left',
    },
    {
      headerName: 'Acc. Status',
      field: 'AccountStatus',
      filter: 'agSetColumnFilter',
      hide: !isAdmin(),
    },
    {
      headerName: 'Act. Subs.',
      field: 'ActiveSubscriptions',
      filter: 'agNumberColumnFilter',
      type: 'rightAligned',
      hide: !isAdmin(),
    },
    {
      headerName: 'Progress',
      children: [
        {
          headerName: 'Refreshed',
          field: 'RefreshTime',
          filter: 'agDateColumnFilter',
          type: 'rightAligned',
          valueGetter: ({ data: { RefreshTime: date } }) => (date ? new Date(date) : date),
          cellRenderer: ({ value }) => formatDateCell(value),
          filterParams: {
            comparator: dateFilterComparator,
          },
          //          columnGroupShow: 'closed',
        },
        {
          headerName: 'Created',
          field: 'CreationTime',
          filter: 'agDateColumnFilter',
          type: 'rightAligned',
          valueGetter: ({ data: { CreationTime: date } }) => (date ? new Date(date) : date),
          cellRenderer: ({ value }) => formatDateCell(value),
          filterParams: {
            comparator: dateFilterComparator,
          },
          hide: !isAdmin(),
          columnGroupShow: 'open',
        },
      ],
    },
    {
      headerName: 'Known URLs',
      field: 'URLKnownCount',
      filter: 'agNumberColumnFilter',
      type: 'rightAligned',
    },
    {
      headerName: 'Inspects Ever',
      field: 'URLInspectedEver',
      filter: 'agNumberColumnFilter',
      type: 'rightAligned',
    },
    {
      headerName: 'Scheduled URLs',
      field: 'URLScheduledCount',
      filter: 'agNumberColumnFilter',
      type: 'rightAligned',
      hide: !isAdmin(),
    },
    {
      headerName: 'Inspected URLs over Time',
      children: [
        {
          headerName: 'Inspected Today',
          field: 'URLInspectedCountToday',
          filter: 'agNumberColumnFilter',
          type: 'rightAligned',
          columnGroupShow: 'always',
        },
        {
          headerName: 'This Week',
          field: 'URLInspectedCountThisWeek',
          filter: 'agNumberColumnFilter',
          type: 'rightAligned',
          columnGroupShow: isAdmin() ? 'open' : 'always',
        },
        {
          headerName: 'This Month',
          field: 'URLInspectedCountThisMonth',
          filter: 'agNumberColumnFilter',
          type: 'rightAligned',
          columnGroupShow: isAdmin() ? 'open' : 'always',
        },
        {
          headerName: 'Total',
          field: 'URLInspectedCount',
          filter: 'agNumberColumnFilter',
          type: 'rightAligned',
          columnGroupShow: isAdmin() ? 'open' : 'always',
        },
      ],
    },
  ],
  // domLayout: 'autoHeight',
  onFirstDataRendered: (event) => {
    return isAdmin() ? event.columnApi.autoSizeAllColumns() : event.api.sizeColumnsToFit();
  },
  defaultColDef: {
    // flex: 1,
    resizable: true,
    sortable: true,
    filter: true,
    floatingFilter: true,
    // wrapText: true,
    // autoHeight: true,
  },

  statusBar: {
    statusPanels: [
      { statusPanel: 'agTotalRowCountComponent' },
      { statusPanel: 'agFilteredRowCountComponent' },
    ],
  },

  localeText: {
    totalRows: 'Total Monitored Properties',
  },

  // sideBar: ['columns', 'filters'],
  rowHeight: 40,

  defaultCsvExportParams: {
    fileName: `${getBaseExportFileName()}.csv`,
  },

  defaultExcelExportParams: {
    fileName: `${getBaseExportFileName()}.xlsx`,
  },

  rowClassRules: {
    'bg-danger bg-opacity-50': (params) => isDeletedMonitoringProfile(params.node.data.JobStatusNo),
  },
};

function getBaseExportFileName() {
  return `URLinspector_monitoring-profiles_${new Date().toISOString().split('T')[0]}`;
}

// fetch data from API endpoint and initialize monitored properties grid
async function initMonitoringProfilesGrid(containerID, dataURL) {
  const monitoredPropertiesGridContainer = document.querySelector(`#${containerID}`);

  monitoredPropertiesGridOptions.rowData = await (await fetch(dataURL)).json();

  return new ag.Grid(monitoredPropertiesGridContainer, monitoredPropertiesGridOptions);
}

export { initMonitoringProfilesGrid, monitoredPropertiesGridOptions };
