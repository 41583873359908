import * as ag from 'ag-grid-enterprise';
import sanitize from 'sanitize-filename';
import { Alert } from 'mdb-ui-kit';
import md5 from 'md5';
import { formatCellWithURLs, formatLink } from './formatters';

const CTRPercentageMultiplier = 100;

// hide chart loading container after creation of chart
function hideChartLoadingContainer(column) {
  document.querySelector(`#${column}Chart div.loading`).classList.add('d-none');
}

// helper to create pie chart for column and grid params
function createPieChart(column, params) {
  params.api.createCrossFilterChart({
    suppressChartRanges: true,
    chartType: 'pie',
    cellRange: {
      columns: [column, 'InspectionURL'],
    },
    aggFunc: 'count',
    chartThemeName: 'URLI',
    chartThemeOverrides: {
      common: {
        title: {
          enabled: false,
          fontSize: 42,
          fontFamily: 'Arial, sans-serif',
        },
      },
      pie: {
        series: {
          title: {
            enabled: false,
          },
          label: {
            enabled: false,
          },
        },
        legend: {
          enabled: true,
          position: 'bottom',
        },
      },
    },
    chartContainer: document.querySelector(`#${column}Chart`),
  });

  hideChartLoadingContainer(column);
}

// helper to create bar chart for column, agg. function and grid params
function createBarChart(column1, aggFunc, params) {
  const column2 = `${column1}Cluster`;

  params.api.createCrossFilterChart({
    suppressChartRanges: true,
    chartType: 'bar',
    cellRange: {
      columns: [column2, column1],
    },
    aggFunc,
    chartThemeName: 'URLI',
    chartThemeOverrides: {
      common: {
        title: {
          enabled: false,
          fontSize: 24,
          fontFamily: 'Arial, sans-serif',
        },
      },
      bar: {
        series: {
          label: {
            enabled: false,
          },
          tooltip: {
            renderer: (params) => ({
              content:
                aggFunc === 'sum'
                  ? `This URL Cluster brought <b>${params.yValue}</b> ${column1} to your site in the last Month. Click to see them in the table.`
                  : `This URL Cluster has <b>${params.yValue}</b> URLs. Click to see them in the table.`,
            }),
          },
        },
        legend: {
          enabled: false,
          position: 'bottom',
        },
        axes: {
          category: {
            title: {
              text: 'Cluster ID',
              enabled: true,
            },
          },
          number: {
            title: {
              text: aggFunc === 'sum' ? column1 : 'URLs',
              enabled: true,
            },
          },
        },
      },
    },

    chartContainer: document.querySelector(`#${column1}${aggFunc}Chart`),
  });

  hideChartLoadingContainer(`${column1}${aggFunc}`);
}

// custom chart tooltip renderers for histogram charts
const histogramChartTooltipRenderer = {
  DaysSinceLastCrawl: (params) => {
    return {
      content: `<b>${params.datum.frequency}</b> URL(s) were last time crawled
                <b>${params.xValue[0]} - ${params.xValue[1]}</b> days ago`,
    };
  },

  DaysSinceLastInspect: (params) => {
    return {
      content: `<b>${params.datum.frequency}</b> URL(s) were last time inspected
                <b>${params.xValue[0]} - ${params.xValue[1]}</b> days ago`,
    };
  },
};

// helper to create histogram chart for column, grid params and histogram series bins
function createHistogramChart(column, params, bins, leftAxisTitle, bottomAxisTitle) {
  params.api.createCrossFilterChart({
    suppressChartRanges: true,
    chartType: 'histogram',
    chartThemeName: 'URLI',
    cellRange: {
      columns: [column],
    },
    chartThemeOverrides: {
      common: {
        title: {
          enabled: false,
        },
      },
      histogram: {
        series: {
          listeners: {
            nodeClick: (event) => {
              params.api.setFilterModel({
                [event.xKey]: {
                  condition1: {
                    type: 'greaterThan',
                    filter: event.datum.domain[0],
                  },
                  condition2: {
                    type: 'lessThanOrEqual',
                    filter: event.datum.domain[1],
                  },
                  filterType: 'number',
                  operator: 'AND',
                },
              });
            },
          },
          label: {
            enabled: false,
          },
          bins,
          tooltip: {
            renderer: histogramChartTooltipRenderer[column],
          },
        },
        legend: {
          enabled: false,
        },
        axes: {
          number: {
            left: {
              min: 0, // hide the first bin with filtered out data (-1 to 0)
              title: {
                text: leftAxisTitle,
                enabled: true,
              },
            },
            bottom: {
              min: 0, // hide the first bin with filtered out data (-1 to 0)
              title: {
                text: bottomAxisTitle,
                enabled: true,
              },
            },
          },
          bins: {
            min: 0, // hide the first bin with filtered out data (-1 to 0)
          },
        },
      },
    },
    chartContainer: document.querySelector(`#${column}Chart`),
  });

  hideChartLoadingContainer(column);
}

// autosize all columns and initialize all charts on first data rendered grid event
function onFirstDataRendered(params) {
  params.columnApi.autoSizeAllColumns();

  Object.keys(chartsConfig).forEach((chartsConfigKey) => createPieChart(chartsConfigKey, params));
  Object.keys(chartsConfig2).forEach((chartsConfigKey2) =>
    createPieChart(chartsConfigKey2, params)
  );

  // loop thru chartsConfig4 and get two values for each chart to pass to createAreaSumChart
  Object.keys(chartsConfig4).forEach((chartsConfigKey4) => {
    const [, aggfunc, column] = chartsConfig4[chartsConfigKey4];

    createBarChart(column, aggfunc, params);
  });

  createHistogramChart(
    'DaysSinceLastCrawl',
    params,
    [
      [-1, 0],
      [0, 1],
      [1, 2],
      [3, 4],
      [4, 5],
      [6, 7],
      [7, 8],
      [8, 15],
      [15, 30],
      [30, 60],
      [60, 90],
      [90, 120],
      [120, 180],
      [180, 365],
      [365, 999],
    ],
    'URLs crawled',
    'Days ago'
  );

  createHistogramChart(
    'DaysSinceLastInspect',
    params,
    [
      [-1, 0],
      [0, 1],
      [1, 2],
      [3, 4],
      [4, 5],
      [6, 7],
      [7, 8],
      [8, 15],
      [15, 30],
      [30, 60],
      [60, 90],
    ],
    'URLs inspected',
    'Days ago'
  );
}

// configure columns, formatters, filters and grid options for inspection results grid
const inspectionResultsGridOptions = {
  tooltipShowDelay: 80,
  enableCharts: false,
  enableRangeSelection: true,
  columnDefs: [
    {
      headerName: 'URL',
      field: 'InspectionURL',
      filter: 'agTextColumnFilter',
      pinned: 'left',
      minWidth: 150,
      flex: 5,
      cellRenderer: (params) => formatLink(params.value),
      chartDataType: 'series',
    },
    {
      headerName: 'Impressions',
      field: 'Impressions',
      suppressMenu: true,
      headerTooltip: 'Impressions in the last 30 days for this URL',
      filter: 'agNumberColumnFilter',
      chartDataType: 'series',
      type: 'rightAligned',
      flex: 1,
      enableRowGroup: true,
    },
    {
      headerName: 'Clicks',
      field: 'Clicks',
      suppressMenu: true,
      headerTooltip: 'Clicks from the SERP in the last 30 days for this URL',
      filter: 'agNumberColumnFilter',
      chartDataType: 'series',
      type: 'rightAligned',
      flex: 1,
      enableRowGroup: true,
    },
    {
      headerName: 'CTR',
      field: 'CTR2',
      suppressMenu: true,
      headerTooltip: 'CTR for Clicks from the SERP / Impressions in the last 30 days for this URL',
      filter: 'agNumberColumnFilter',
      type: 'rightAligned',
      valueFormatter: ({ value }) => (value ? `${value / CTRPercentageMultiplier} %` : value),
      flex: 1,
      enableRowGroup: true,
    },
    {
      headerName: 'I-Days',
      field: 'DaysSinceLastInspect',
      suppressMenu: true,
      headerTooltip: 'Inspect Days - days since we last the URL Inspection API for this URL',
      filter: 'agNumberColumnFilter',
      type: 'rightAligned',
      flex: 1,
      enableRowGroup: true,
    },
    {
      headerName: 'C-Days',
      field: 'DaysSinceLastCrawl',
      suppressMenu: true,
      headerTooltip: 'Crawl Days - days since says they Google last crawled this URL',
      filter: 'agNumberColumnFilter',
      chartDataType: 'series',
      type: 'rightAligned',
      flex: 1,
      enableRowGroup: true,
    },
    {
      headerName: 'Idx',
      headerTooltip: 'Is the URL indexed by Google?',
      field: 'OnGoogle',
      menuTabs: [],
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      flex: 1,
      enableValue: false,
      enableRowGroup: true,
    },
    {
      headerName: 'Match',
      headerTooltip: 'Do User URL and Google Canonicals URL Match?',
      suppressMenu: true,
      field: 'Match',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      flex: 1,
      enableValue: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Coverage',
      field: 'CoverageState',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableValue: true,
      enableRowGroup: true,
      minWidth: 100,
      maxWidth: 230,
      width: 150,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Last Crawl',
      field: 'LastCrawlDay',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      type: 'rightAligned',
      enableRowGroup: true,
    },
    {
      headerName: 'Issues',
      headerTooltip: 'Number of Mobile Issues found',
      field: 'MobileIssueCount',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableValue: true,
      enableRowGroup: true,
      type: 'rightAligned',
    },
    {
      headerName: 'Items',
      headerTooltip: 'Rich Items found',
      field: 'RichItemCount',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableValue: true,
      enableRowGroup: true,
      type: 'rightAligned',
    },
    {
      headerName: 'Verdict',
      field: 'Verdict',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableValue: true,
      enableRowGroup: true,
    },
    {
      headerName: 'MobileVerdict',
      field: 'MobileVerdict',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableValue: true,
      enableRowGroup: true,
    },
    {
      headerName: 'RichVerdict',
      field: 'RichVerdict',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableValue: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Crawled as',
      headerTooltip: 'Which User Agent did Google crawl this URL?',
      field: 'CrawledAs',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableValue: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Robots TXT',
      field: 'RobotsTxtState',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableValue: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Indexing',
      field: 'IndexingState',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableValue: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Page Fetch',
      field: 'PageFetchState',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      enableValue: true,
      enableRowGroup: true,
    },
    {
      headerName: 'Google Canonical URL',
      field: 'GoogleCanonicalURL',
      filter: 'agTextColumnFilter',
      cellRenderer: (params) => formatLink(params.value),
      minWidth: 150,
      flex: 5,
    },
    {
      headerName: 'User Canonical URL',
      field: 'UserCanonicalURL',
      filter: 'agTextColumnFilter',
      cellRenderer: (params) => formatLink(params.value),
    },
    {
      headerName: 'Sitemaps',
      field: 'Sitemaps',
      filter: 'agTextColumnFilter',
      cellRenderer: formatCellWithURLs,
    },
    {
      headerName: 'Referring URLs',
      field: 'ReferringURLs',
      filter: 'agTextColumnFilter',
      cellRenderer: formatCellWithURLs,
    },
    {
      headerName: 'Last Inspect',
      field: 'LastInspectDay',
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      type: 'rightAligned',
      enableRowGroup: true,
    },
    {
      headerName: 'Brand',
      field: 'Brand',
      filter: 'agSetColumnFilter',
      cellRenderer: (params) => formatLink(params.value),
      chartDataType: 'category',
      enableRowGroup: true,
    },
    {
      headerName: 'RootDomain',
      field: 'RootDomain',
      filter: 'agSetColumnFilter',
      cellRenderer: (params) => formatLink(params.value),
      chartDataType: 'category',
      enableRowGroup: true,
    },
    {
      headerName: 'Domain',
      field: 'Domain',
      filter: 'agSetColumnFilter',
      cellRenderer: (params) => formatLink(params.value),
      chartDataType: 'category',
      enableRowGroup: true,
    },
    {
      headerName: 'Path',
      field: 'Path',
      filter: 'agTextColumnFilter',
      cellRenderer: (params) => formatLink(params.value),
      chartDataType: 'series',
      enableRowGroup: true,
    },
    {
      headerName: 'Filename',
      field: 'Filename',
      filter: 'agTextColumnFilter',
      cellRenderer: (params) => formatLink(params.value),
      chartDataType: 'series',
      enableRowGroup: true,
    },
    {
      headerName: 'Impression Cluster',
      headerTooltip: 'Cluster of URLs by Impressions.',
      field: 'ImpressionsCluster',
      menuTabs: [],
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      flex: 1,
      enableValue: false,
      enableRowGroup: true,
    },
    {
      headerName: 'Click Cluster',
      headerTooltip: 'Cluster of of URLs by Clicks.',
      field: 'ClicksCluster',
      menuTabs: [],
      filter: 'agSetColumnFilter',
      chartDataType: 'category',
      flex: 1,
      enableValue: false,
      enableRowGroup: true,
    },
  ],
  // domLayout: 'autoHeight',
  onFirstDataRendered,
  defaultColDef: {
    // flex: 1,
    // minWidth: 150,
    resizable: true,
    sortable: true,
    filter: true,
    floatingFilter: true,
  },

  statusBar: {
    statusPanels: [
      { statusPanel: 'agTotalRowCountComponent' },
      { statusPanel: 'agFilteredRowCountComponent' },
    ],
  },

  localeText: {
    totalRows: 'Total Inspection Results',
  },

  sideBar: {
    toolPanels: ['columns', 'filters'],
  },

  defaultCsvExportParams: {
    fileName: `${getBaseExportFileName()}.csv`,
  },

  defaultExcelExportParams: {
    fileName: `${getBaseExportFileName()}.xlsx`,
  },

  overlayLoadingTemplate: `<div class="loading loading-spinner">
        <div class="spinner-border loading-icon text-info" role="status"></div>
        <span class="loading-text mt-3 text-info">Loading...</span>
    </div>`,

  // update chart fills palette based on configured palette and shown data values on these events
  onChartCreated: (event) => updateChartFillsPalette(event, event.chartId),
  onModelUpdated: updateAllChartsFillsPalette,
  onAsyncTransactionsFlushed: updateAllChartsFillsPalette,
  onColumnVisible: updateAllChartsFillsPalette,

  // custom URLI chart theme definition
  customChartThemes: {
    URLI: {
      baseTheme: 'ag-vivid',
      palette: {
        fills: ['#5797FF'],
        strokes: ['#FFFFFF'],
      },
      overrides: {
        pie: {
          series: {
            highlightStyle: {
              item: {
                fill: '#5797FF4D',
                stroke: '#FFFFFF',
              },
            },
          },
        },
        histogram: {
          series: {
            highlightStyle: {
              item: {
                fill: '#5797FF4D',
                stroke: '#FFFFFF',
              },
            },
          },
        },
        bar: {
          series: {
            highlightStyle: {
              item: {
                fill: '#5797FF4D',
                stroke: '#FFFFFF',
              },
            },
          },
        },
      },
    },
  },
  chartThemes: ['URLI'],
};

// update chart fills palette based on configured palette and shown data values
function updateChartFillsPalette(event, chartID) {
  // retrieve chart via AG Grid API using chartID
  const chart = event.api.getChartRef(chartID);

  chart.chart.series.forEach((chartSeries, chartSeriesIndex) => {
    // charts without series label key are not supported (e.g. histograms)
    if (typeof chartSeries.labelKey === 'undefined') {
      return;
    }

    const fills = [];
    const strokes = [];

    chartSeries.data.forEach((chartDataItem) => {
      // get color from palette based on series label key (column name) and data value
      let fillColor =
        chartValueColorsMapping?.[chartSeries.labelKey]?.[chartDataItem[chartSeries.labelKey]];

      // if color is not defined, use fallback to generate unique color
      if (typeof fillColor === 'undefined') {
        fillColor = generateChartFillColor(chartDataItem[chartSeries.labelKey]);
      }

      // if there are multiple chart series, reduce opacity of the fill color for the first series
      if (chart.chart.series.length > 1 && chartSeriesIndex < 1) {
        fillColor += '4d'; // 30% opacity
      }

      fills.push(fillColor);
      strokes.push('#fff'); // use white stroke color
    });

    chartSeries.fills = fills;
    chartSeries.strokes = strokes;
  });
}

// get all configured chart models and update their fills palette
function updateAllChartsFillsPalette(event) {
  const chartModels = event.api.getChartModels();

  chartModels.forEach((chartModel) => {
    updateChartFillsPalette(event, chartModel.chartId);
  });
}

// function to generate unique HEX color code based on string input
function generateChartFillColor(string) {
  // use hash of current timestamp as fallback when string is empty
  if (string === null || typeof string === 'undefined') {
    string = md5(Date.now().toString());
  }

  const hash = md5(string);
  const hex = hash.substring(0, 6);
  return `#${hex}`;
}

// mapping of chart series label keys to colors for chart fills palette
const chartValueColorsMapping = {
  OnGoogle: {
    OK: '#0f9d58',
    NOT: '#db4437',
    WARN: '#f39910',
  },

  Match: {
    Y: '#0f9d58',
    N: '#db4437',
  },

  Verdict: {
    '': '#aeaeae',
    VERDICT_UNSPECIFIED: '#aeaeae',
    NEUTRAL: '#5591f5',
    PARTIAL: '#eeb211',
    PASS: '#0f9d58',
    FAIL: '#db4437',
  },

  MobileVerdict: {
    '': '#aeaeae',
    NEUTRAL: '#5591f5',
    PARTIAL: '#eeb211',
    PASS: '#0f9d58',
    FAIL: '#db4437',
  },

  RichVerdict: {
    '': '#aeaeae',
    NEUTRAL: '#5591f5',
    PARTIAL: '#eeb211',
    PASS: '#0f9d58',
    FAIL: '#db4437',
  },

  IndexingState: {
    INDEXING_STATE_UNSPECIFIED: '#aeaeae',
    NEUTRAL: '#5591f5',
    INDEXING_ALLOWED: '#0f9d58',
    BLOCKED_BY_ROBOTS_TXT: '#db4437',
    BLOCKED_BY_HTTP_HEADER: '#c53d32',
    BLOCKED_BY_META_TAG: '#af362c',
  },

  PageFetchState: {
    PAGE_FETCH_STATE_UNSPECIFIED: '#aeaeae',
    SUCCESSFUL: '#0f9d58',

    // ORANGE - #f39910
    // error
    ACCESS_DENIED: '#f39910',
    NOT_FOUND: '#db8a0e',
    ACCESS_FORBIDDEN: '#c27a0d',
    SERVER_ERROR: '#aa6b0b',
    REDIRECT_ERROR: '#925c0a',
    INTERNAL_CRAWL_ERROR: '#7a4d08',
    INVALID_URL: '#613d06',
    SOFT_404: '#492e05',

    // RED - #db4437
    // blocked
    BLOCKED_4XX: '#db4437',
    BLOCKED_ROBOTS_TXT: '#c53d32',
  },

  RobotsTxtState: {
    ROBOTS_TXT_STATE_UNSPECIFIED: '#aeaeae',
    ALLOWED: '#0f9d58',
    DISALLOWED: '#db4437',
  },

  CrawledAs: {
    MOBILE: '#0f9d58',
    DESKTOP: '#f39910',
    '': '#aeaeae',
  },

  CoverageState: {
    // RED - #db4437
    // blocked
    // unknown
    'URL is unknown to Google': '#df574b',
    'Blocked by robots.txt': '#db4437',
    'Blocked due to access forbidden (403)': '#c53d32',
    'Indexed, though blocked by robots.txt': '#af362c',
    'Submitted URL blocked by robots.txt': '#993027',
    'Submitted URL blocked due to other 4xx issue': '#832921',

    // noindex
    'Excluded by ‘noindex’ tag': '#6e221c',
    'Submitted URL marked ‘noindex’': '#581b16',

    // not indexed
    'Crawled - currently not indexed': '#421410',
    'Discovered - currently not indexed': '#2c0e0b',

    // ORANGE - #f39910
    // error
    'Not found (404)': '#f39910',
    'Redirect error': '#db8a0e',
    'Server error (5xx)': '#c27a0d',
    'Submitted URL not found (404)': '#aa6b0b',
    'Submitted URL returned 403': '#925c0a',
    'Soft 404': '#7a4d08',
    'Submitted URL seems to be a Soft 404': '#492e05',
    'Page indexed without content': '#311f03',

    // PURPLE - #7e4aa3
    // redirects
    'Page with redirect': '#714393',

    // YELLOW - #eeb211
    // canonical
    'Duplicate without user-selected canonical': '#eeb211',
    'Duplicate, Google chose different canonical than user': '#d6a00f',
    'Duplicate, submitted URL not selected as canonical': '#be8e0e',
    'Alternate page with proper canonical tag': '#a77d0c',

    // GREEN - #0f9d58
    // indexed
    'Submitted and indexed': '#0f9d58',
    'Indexed, not submitted in sitemap': '#0e8d4f',
  },
};

function getBaseExportFileName() {
  return `URLinspector_${sanitize(window?.URLinspector?.User?.PropertyFilter || '')}_${
    new Date().toISOString().split('T')[0]
  }`;
}

const noResultsLoaderContainerID = 'noResultsLoader';

function addNoResultsLoaderAlert() {
  const alert = document.createElement('div');

  alert.innerHTML = `
      <div class="spinner-border text-info flex-shrink-0 me-3" role="status">
         <span class="visually-hidden">Processing inspection results...</span>
      </div>
      <span>We have not found any inspection results for this monitoring profile, yet
      - please stand by while we're processing inspection results...</span>
    `;

  alert.setAttribute('id', noResultsLoaderContainerID);
  alert.classList.add('alert', 'fade', 'mt-3', 'd-flex', 'align-items-center');
  document.body.appendChild(alert);

  const alertInstance = new Alert(alert, {
    position: 'top-center',
    hidden: true,
    width: '25%',
    color: 'info',
  });
  alertInstance.show();
}

// initialize event source for server sent events and add new results to grid on message using async transaction
function initEventSource(dataURL, lastEventID, grid, filterSliceTypeNo) {
  const eventSource = new EventSource(
    `${dataURL}/stream?lastEventID=${lastEventID}&filterSliceTypeNo=${filterSliceTypeNo || ''}`,
    {
      withCredentials: true,
    }
  );

  eventSource.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (data && data.length) {
      Alert.getOrCreateInstance(document.getElementById(noResultsLoaderContainerID)).hide();
    }

    grid.gridOptions.api.applyTransactionAsync({ add: data });
  };

  return eventSource;
}

// fetch data from API endpoint and initialize inspection results grid
async function initInspectionResultsGrid(containerID, dataURL) {
  const inspectionResultsGridContainer = document.querySelector(`#${containerID}`);
  const grid = new ag.Grid(inspectionResultsGridContainer, inspectionResultsGridOptions);

  const rowData = await (await fetch(dataURL)).json();

  grid.gridOptions.api.showLoadingOverlay();
  grid.gridOptions.api.setRowData(rowData);
  grid.gridOptions.api.hideOverlay();

  if (!rowData || !rowData.length) {
    addNoResultsLoaderAlert();
  }

  const eventSource = initEventSource(dataURL, rowData?.length || 0, grid);

  return [grid, eventSource];
}

// init filter slices panel
function initFilterSlicesPanel(dataURL) {
  const defaultFilterSliceTypeNo = '0';

  return {
    grid: null,
    eventSource: null,
    filterSliceTypeNo: defaultFilterSliceTypeNo,

    resetFilters() {
      this.filterSliceTypeNo = defaultFilterSliceTypeNo;
      this.grid.gridOptions.api.setFilterModel(null);

      this.reload();
    },

    closeEventSource() {
      if (!this.eventSource) {
        return;
      }

      this.eventSource.close();
    },

    sizeToFit() {
      this.grid.gridOptions.api.sizeColumnsToFit();
    },

    sizeAutoMin() {
      const allColumnIds = [];
      this.grid.gridOptions.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });

      this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds, true);
    },

    sizeAutoMax() {
      const allColumnIds = [];
      this.grid.gridOptions.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });

      this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
    },

    reload() {
      this.closeEventSource();

      const filterModel = this.grid.gridOptions.api.getFilterModel();

      this.grid.gridOptions.api.showLoadingOverlay();

      fetch(`${dataURL}.json?filterSliceTypeNo=${this.filterSliceTypeNo}`)
        .then((response) => response.json())
        .then((data) => {
          this.closeEventSource();

          this.grid.gridOptions.api.setRowData(data);

          this.eventSource = initEventSource(
            `${dataURL}.json`,
            data?.length || 0,
            this.grid,
            this.filterSliceTypeNo
          );
        })
        .catch(() => {
          this.grid.gridOptions.api.setRowData([]);
          Alert.getOrCreateInstance(document.getElementById('errorLoadingResults')).show();
        })
        .finally(() => {
          this.grid.gridOptions.api.setFilterModel(filterModel);
          this.grid.gridOptions.api.hideOverlay();
        });
    },
  };
}

// charts config for chart container ID/grid column and chart title mapping
const chartsConfig = {
  Match: 'Canonicals Match',
  Verdict: 'Index Verdict',
  MobileVerdict: 'Mobile Verdict',
  RichVerdict: 'Rich Items Verdict',
  IndexingState: 'Indexing',
  PageFetchState: 'Page Fetch',
  RobotsTxtState: 'Robots TXT',
  CrawledAs: 'Crawled as',
  // CoverageState: 'Coverage',
};

const chartsConfig2 = {
  OnGoogle: 'Indexed On Google',
  CoverageState: 'Coverage',
};

const chartsConfig4 = {
  Impressionssum: ['Sum of Impressions in Cluster', 'sum', 'Impressions'],
  Clickssum: ['Sum of Clicks in Cluster', 'sum', 'Clicks'],
  Impressionscount: ['URLs in Impression Cluster', 'count', 'Impressions'],
  Clickscount: ['URLs in Click Cluster', 'count', 'Clicks'],
  // CTR2: ['CTR Clusters', 'avg'],
};

export {
  initInspectionResultsGrid,
  inspectionResultsGridOptions,
  chartsConfig,
  chartsConfig2,
  chartsConfig4,
  initFilterSlicesPanel,
};
