import * as mdb from 'mdb-ui-kit';
import Alpine from 'alpinejs';
import {
  faSearch,
  faEdit,
  faBars,
  faTimesCircle,
  faAngleDoubleRight,
  faDownload,
  faPlay,
  faTrash,
  faExclamationTriangle,
  faPause,
  faStop,
  faExclamation,
  faQuestion,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle as faTimesCircleRegular } from '@fortawesome/free-regular-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { LicenseManager } from 'ag-grid-enterprise';
import Select from '../../mdb/js/pro/select';
import * as MonitoringProfiles from './monitored_properties';
import * as InspectionResults from './monitored_results';
import { formatPopoverLinksList } from './formatters';
import Popover from '../../mdb/js/free/popover';
import '@fontsource/roboto';
import 'htmx.org';

// AG Grid Enterprise license for URLinspector PROD
LicenseManager.setLicenseKey(
  'CompanyName=LinkResearchTools GmbH,LicensedApplication=URLinspector,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-025911,ExpiryDate=28_February_2023_[v2]_MTY3NzU0MjQwMDAwMA==b238b128efb06cc2afd927e150b1dc2c'
);

// Load and use only used Font Awesome icons
library.add(
  faSearch,
  faEdit,
  faGoogle,
  faBars,
  faTimesCircle,
  faAngleDoubleRight,
  faDownload,
  faPlay,
  faTrash,
  faExclamationTriangle,
  faPause,
  faStop,
  faExclamation,
  faQuestion,
  faEye,
  faTimesCircleRegular,
  faEyeSlash
);
dom.watch();

window.mdb = mdb;

window.htmx = require('htmx.org');

window.Alpine = Alpine;

window.URLinspector = window.URLinspector || {};

// initialize MDB select instance after HTMX swapping
window.URLinspector.initSwappableSelect = function (containerID) {
  const container = document.getElementById(containerID);

  container.addEventListener('htmx:afterSettle', () => {
    return new Select(document.querySelector(`#${containerID} select`), { visibleOptions: 10 });
  });
};

// initialize popover links list with formatted list of HTML links
window.URLinspector.initializePopoverLinksList = (element, links) => {
  return new Popover(element, {
    html: true,
    content: formatPopoverLinksList(links),
    customClass: 'urls-cell-popover shadow-lg',
    trigger: 'click',
  });
};

// hide popover instance after click outside of popover container
window.URLinspector.hidePopoverInstanceOutsideClick = (event, popover) => {
  return event.path.filter((element) => element.className && element.className.match('popover'))
    .length === 0
    ? popover.hide()
    : null;
};

// persist confirmation of a Message based on its ID
window.URLinspector.initConfirmMessageModal = function (messageID, CSRFToken, redirectURL) {
  return {
    messageID,
    CSRFToken,
    redirectURL,

    isLoading: false,
    hasErrors: false,

    modal: null,

    init() {
      this.modal = new mdb.Modal(document.getElementById(this.messageID), {
        keyboard: false,
        backdrop: 'static',
      });

      this.modal.show();
    },

    confirm() {
      this.isLoading = true;
      this.hasErrors = false;

      fetch(`/message/${this.messageID}/confirm`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.CSRFToken,
        },
      }).then((response) => {
        this.isLoading = false;

        if (response.ok) {
          this.modal.hide();

          if (this.redirectURL) {
            window.location.href = this.redirectURL;
          }
        } else {
          this.hasErrors = true;
        }
      });
    },
  };
};

// hide all admin features for screenshots using CSS class selector
window.URLinspector.hideAdminFeatures = function () {
  const elements = document.getElementsByClassName('URLI-admin-feature');

  Array.prototype.forEach.call(elements, (element) => {
    element.classList.add('d-none');
  });
};

window.URLinspector.MonitoringProfiles = MonitoringProfiles;
window.URLinspector.InspectionResults = InspectionResults;

Alpine.start();
